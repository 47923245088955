.enhanced_cart_page {
  .viewcart {
    .checkout__content {
      #viewcart-panel {
        .cart-items {
          .price-qty-total-column {
            .cart-item {
              &__discount-percentage {
                display: block;
              }
            }
            div.qty {
              #cart {
                .selectbox {
                  background-position: 10px calc(1em - 3px), 15px calc(1em - 3px);
                  .select2-choice {
                    .select2-arrow {
                      top: -3px;
                      #{$rdirection}: 7px;
                      b {
                        &:before {
                          transform: rotate(-92deg);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .right {
      &.checkout__sidebar {
        .back_to_top {
          .sticky-back-to-top {
            transform: rotate(-45deg);
          }
        }
        .shipmethod-price {
          display: none;
        }
      }
    }
  }
}
